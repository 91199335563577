<template>
  <div class="pc_menu" v-loading="loading">
    <div class="globle_border">
      <div class="search">
        <el-input v-model="search" placeholder="请输入菜单名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <!-- <el-button type="primary" @click="onSearch">搜索</el-button> -->
        <el-button type="primary" @click="handleAdd">新增菜单</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" row-key="menuId" max-height="540" :tree-props="{ children: 'children' }">
          <el-table-column prop="menuName" label="菜单名称"></el-table-column>
          <el-table-column prop="menuOrder" label="排序" width="100"></el-table-column>
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status == 1 ? '' : 'danger'">{{ scope.row.status == 1 ? "正常" : "停用" }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="menuPath" label="路由地址"></el-table-column>
          <el-table-column prop="menuStr" label="权限标识"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="260">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="small" plain @click="handleAddRow(scope.$index, scope.row)">新增</el-button>
              <el-button class="btn" type="" size="small" plain @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button class="btn" type="danger" size="small" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog :title="isAdd ? '新增菜单' : '修改菜单'" :visible.sync="dialogVisible" :before-close="onCancel">
      <!-- 添加 -->
      <el-form v-if="isAdd" :model="addForm" :rules="rules" ref="addForm" label-width="150px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="菜单类型" prop="menuType">
              <el-radio-group v-model="addForm.menuType">
                <el-radio label="0">菜单</el-radio>
                <el-radio label="1">按钮</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="parent_menu">
            <el-form-item label="上级菜单" prop="menuId">
              <el-cascader v-model="addForm.menuId" :options="tableData" :props="{ expandTrigger: 'hover', value: 'menuId', checkStrictly: true, label: 'menuName' }" @change="changeCascader" clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="addForm.menuType == 0" label="菜单名称" prop="menuName">
              <el-input v-model="addForm.menuName" placeholder="请输入菜单名称"></el-input>
            </el-form-item>
            <el-form-item v-if="addForm.menuType == 1" label="按钮名称" prop="menuName">
              <el-input v-model="addForm.menuName" placeholder="请输入按钮名称"></el-input>
            </el-form-item>
            <el-form-item label="权限标识" prop="menuStr">
              <el-input v-model="addForm.menuStr" placeholder="请输入权限标识"></el-input>
            </el-form-item>
            <el-form-item v-if="addForm.menuType == 0" label="组件路径" prop="menuComponent">
              <el-input v-model="addForm.menuComponent" placeholder="请输入组件路径"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单排序" prop="menuOrder">
              <el-input-number v-model="addForm.menuOrder" controls-position="right" :min="1"></el-input-number>
            </el-form-item>
            <el-form-item label="路由地址" prop="menuPath">
              <el-input v-model="addForm.menuPath" placeholder="请输入路由地址"></el-input>
            </el-form-item>
            <el-form-item v-if="addForm.menuType == 0" label="路由参数" prop="menuParameter">
              <el-input v-model="addForm.menuParameter" placeholder="请输入路由参数"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!-- 编辑 -->
      <el-form v-else :model="editForm" :rules="rules" ref="editForm" label-width="150px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="菜单类型" prop="menuType">
              <el-radio-group v-model="editForm.menuType">
                <el-radio label="0">菜单</el-radio>
                <el-radio label="1">按钮</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24" class="parent_menu">
            <el-form-item label="上级菜单" prop="parentId">
              <el-cascader v-model="editForm.parentId" :options="tableData" :props="{ expandTrigger: 'hover', value: 'menuId', checkStrictly: true, label: 'menuName' }" @change="changeCascader" clearable></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="editForm.menuType == 0" label="菜单名称" prop="menuName">
              <el-input v-model="editForm.menuName" placeholder="请输入菜单名称"></el-input>
            </el-form-item>
            <el-form-item v-if="editForm.menuType == 1" label="按钮名称" prop="menuName">
              <el-input v-model="editForm.menuName" placeholder="请输入按钮名称"></el-input>
            </el-form-item>
            <el-form-item label="权限标识" prop="menuStr">
              <el-input v-model="editForm.menuStr" placeholder="请输入权限标识"></el-input>
            </el-form-item>
            <el-form-item v-if="editForm.menuType == 0" label="组件路径" prop="menuComponent">
              <el-input v-model="editForm.menuComponent" placeholder="请输入组件路径"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单排序" prop="menuOrder">
              <el-input-number v-model="editForm.menuOrder" controls-position="right" :min="1"></el-input-number>
            </el-form-item>
            <el-form-item label="路由地址" prop="menuPath">
              <el-input v-model="editForm.menuPath" placeholder="请输入路由地址"></el-input>
            </el-form-item>
            <el-form-item v-if="editForm.menuType == 0" label="路由参数" prop="menuParameter">
              <el-input v-model="editForm.menuParameter" placeholder="请输入路由参数"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm(isAdd ? 'addForm' : 'editForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      submitLoding: false,
      search: "",
      tableData: [],
      dialogVisible: false,
      isAdd: true,
      editForm: {},
      addForm: {
        menuType: "0",
        menuId: "0",
        menuName: "",
        menuPath: "",
        menuParameter: "",
        menuOrder: "1",
        menuStr: "",
        menuComponent: "",
      },
      rules: {
        menuType: [{ required: true, message: "请选择菜单类型", trigger: "submit" }],
        menuName: [{ required: true, message: "请输入菜单名称", trigger: "submit" }],
        menuOrder: [{ required: true, message: "请输入菜单排序", trigger: "submit" }],
        menuPath: [{ required: true, message: "请输入路由地址", trigger: "submit" }],
        menuStr: [{ required: true, message: "请输入权限标识", trigger: "submit" }],
      },
    }
  },
  created() {
    this.getSysMenuList()
  },
  watch: {},
  methods: {
    // 菜单列表
    getSysMenuList() {
      this.loading = true
      this.$axios
        .get(this.$api.getSysMenuList, {
          params: {
            menuName: this.search,
            menuList: "0", // 0：PC端菜单,1：移动端菜单
          },
        })
        .then(res => {
          this.tableData = res.data.result
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 搜索
    onSearch() {
      this.getSysMenuList()
    },
    // 新增菜单
    handleAdd() {
      this.isAdd = true
      this.addForm = {
        menuType: "0",
        menuId: "0",
        menuName: "",
        menuPath: "",
        menuParameter: "",
        menuOrder: "1",
        menuStr: "",
        menuComponent: "",
      }
      this.dialogVisible = true
    },
    // 新增指定子菜单
    handleAddRow(index, row) {
      this.isAdd = true
      this.addForm = {
        menuType: "0",
        menuId: "0",
        menuName: "",
        menuPath: "",
        menuParameter: "",
        menuOrder: "1",
        menuStr: "",
        menuComponent: "",
      }
      this.addForm.menuId = row.menuId
      this.addForm.menuPath = row.menuPath
      this.addForm.menuStr = row.menuStr
      this.dialogVisible = true
    },
    // 修改菜单
    handleEdit(index, row) {
      this.isAdd = false
      this.editForm = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该菜单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios.put(`${this.$api.deleteSysMenu}/${row.menuId}`).then(res => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.getSysMenuList()
          })
        })
        .catch(() => {})
    },
    // 关闭对话框
    onCancel() {
      this.dialogVisible = false
      this.submitLoding = false
      this.resetForm()
    },
    // 切换上级菜单
    changeCascader(arr) {
      // 截取arr最后一个元素
      if (this.isAdd) {
        if (arr.length > 0) {
          this.addForm.menuId = arr[arr.length - 1]
        } else {
          this.addForm.menuId = "0"
        }
      } else {
        if (arr.length > 0) {
          this.editForm.parentId = arr[arr.length - 1]
        } else {
          this.editForm.parentId = "0"
        }
      }
    },
    // 提交表单
    submitForm(formName) {
      this.submitLoding = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.$axios
              .post(this.$api.addSysMenu, {
                menuList: "0", // 0：PC端菜单,1：移动端菜单
                menuType: this.addForm.menuType,
                parentId: this.addForm.menuId,
                menuName: this.addForm.menuName,
                menuPath: this.addForm.menuPath,
                menuParameter: this.addForm.menuParameter,
                menuOrder: this.addForm.menuOrder,
                menuStr: this.addForm.menuStr,
                menuComponent: this.addForm.menuComponent,
              })
              .then(res => {
                this.$message.success(res.data.desc)
                this.getSysMenuList()
                this.submitLoding = false
                this.dialogVisible = false
                this.resetForm()
              })
          } else {
            this.$axios
              .put(this.$api.updateSysMenu, {
                menuId: this.editForm.menuId,
                menuType: this.editForm.menuType,
                parentId: this.editForm.parentId,
                menuName: this.editForm.menuName,
                menuPath: this.editForm.menuPath,
                menuParameter: this.editForm.menuParameter,
                menuOrder: this.editForm.menuOrder,
                menuStr: this.editForm.menuStr,
                menuComponent: this.editForm.menuComponent,
              })
              .then(res => {
                this.$message.success(res.data.desc)
                this.getSysMenuList()
                this.submitLoding = false
                this.dialogVisible = false
                this.resetForm()
              })
          }
        } else {
          this.$message.error("提交失败")
          this.submitLoding = false
          return false
        }
      })
    },
    // 重置校验
    resetForm() {
      if (this.isAdd) {
        this.$refs.addForm.resetFields()
      } else {
        this.$refs.editForm.resetFields()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.pc_menu {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  /deep/ .el-radio__inner {
    width: 16px;
    height: 16px;
  }

  /deep/ .el-radio__label {
    font-size: 16px;
  }

  .parent_menu {
    /deep/ .el-input,
    /deep/ .el-input__inner {
      width: 736px;
    }
  }

  /deep/.el-input-number {
    width: 255px;
  }
}
</style>
